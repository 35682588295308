import React from 'react'

import type {
  BrandIconType,
  IconType,
} from '../../../../../equity/particles/icons'
import type { EventType } from '../../../../util/contentful/types'
import type { Maybe } from '../../../../util/graphql/mfe-contentful'
import type { CloudinaryImage } from '../../../cloudinary-image'
import type { ContentfulImage } from '../../../contentful-image'

export type CtaAndIconType =
  | Exclude<PageTypes, 'Webinar' | 'ExternalContent'>
  | 'Event_Webinar'
  | 'Event_In-Person_Upcoming'
  | 'Event_In-Person_Full'
  | 'Event_In-Person_Almost-full'
  | 'Event_In-Person_Open'
  | ExternalContentPageTypes

enum PageType {
  'CaisiqArticle' = 'CaisiqArticle',
  'CaisiqVideo' = 'CaisiqVideo',
  'CaisiqVideoPlaylist' = 'CaisiqVideoPlaylist',
  'Event' = 'Event',
  'Webinar' = 'Webinar',
  'Whitepaper' = 'Whitepaper',
  'ExternalContent' = 'ExternalContent',
  'ComponentText' = 'ComponentText',
  'CmsDocument' = 'CmsDocument',
  'CmsPdf' = 'CmsPdf',
  'Pdf' = 'Pdf',
}

export type PageTypes = keyof typeof PageType

export const contentNames: Record<PageTypes | string, string> = {
  CaisiqArticle: 'Article',
  CaisiqVideo: 'Video',
  CaisiqVideoPlaylist: 'Video',
  Event: 'Event',
  PwsPressRelease: 'Press Release',
  Webinar: 'Webinar',
  Whitepaper: 'Whitepaper',
  ComponentText: 'Text',
  Pdf: 'CmsDocument',
} as const

enum ExternalContentPageType {
  'Fund' = 'Fund',
  'Video' = 'Video',
  'Article' = 'Article',
  'PDF' = 'PDF',
}

enum ExternalContentTagType {
  'Product' = 'Product',
  'Video' = 'Video',
  'Article' = 'Article',
  'PDF' = 'PDF',
}

export type ExternalContentPageTypes = keyof typeof ExternalContentPageType

type ExternalContentTagTypes = keyof typeof ExternalContentTagType

export const externalContentNames: Record<
  ExternalContentPageTypes,
  ExternalContentTagTypes
> = {
  Fund: 'Product',
  Video: 'Video',
  Article: 'Article',
  PDF: 'PDF',
} as const

export type Image = {
  url: string
  height?: number
  width?: number
  alt?: string
  title?: string
}

export type CardProps = {
  callToAction: string
  __typename: PageTypes
  date?: string
  href: string
  title: string
  description?: React.ReactNode
  icon?: {
    url: string
    height: number
    width: number
  }
  imageUrl?: string
  imageComponent?: typeof ContentfulImage | typeof CloudinaryImage
  includeTime?: boolean
  thumbnail?: Image
  time?: string
  timezone?: string
  testId?: string
  id?: string
  type?: ExternalContentPageTypes
  eventType?: EventType['eventType']
  externalContentDetails?: GridItemProps[]
  onClick?: () => void
  status?: string
  tag?: string
  byCais?: boolean
  contentOwnershipTag?: {
    name?: string
    id?: string
  }
}

export type TextCardProps = {
  brandIcon?: BrandIconType
  description: JSX.Element[] | null
  sys?: { id: string }
  title?: string
}

export const cardVariantTypes = {
  default: 'default',
  curated: 'curated',
  'curated-primary': 'curated-primary',
  'curated-primary-deprecated': 'curated-primary-deprecated',
  'curated-secondary-deprecated': 'curated-secondary-deprecated',
  featured: 'featured',
  'featured-compact': 'featured-compact',
  text: 'text',
} as const

export type CuratedContentCardProps = {
  callToAction: {
    text: string
    href: string
    icon?: IconType
  }
  __typename: PageTypes
  description: string
  title: string
  testId?: string
  id?: string
  iconType: IconType
  contentOwnershipTag?: {
    name?: string
    id?: string
  }
  date?: string
  timezone?: string
  byCais?: boolean
  onClick?: () => void
}
export type GridItemProps = {
  label: string
  description?: Maybe<string>
}
