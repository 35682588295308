import type { PropsWithChildren } from 'react'

import {
  mapTrackingKeysToDataAttributes,
  type Tracking,
} from '../../../../../equity/utils/tracking-utils'

import { cardVariantTypes as variantTypes } from './types'

const afterStyles =
  '[&_a]:after:absolute [&_a]:after:inset-0 [&_a]:after:z-10 [&_a]:after:content-[""]'

const defaultBackground = 'bg-neutral-0'
const curated = `${afterStyles} gap-8 border border-solid p-16 border-transparent focus:border-primary-600 has-[a]:hover:shadow-2`

// TODO update CSS to apply hover shadow when only one link is present?

const variants: Record<keyof typeof variantTypes, string> = {
  default: `${afterStyles} ${defaultBackground} border border-solid border-neutral-200 has-[a]:hover:shadow-2`,
  curated: `${defaultBackground} ${curated}`,
  'curated-primary': `${curated} bg-brand-100 hover:border-brand-200`,
  'curated-primary-deprecated': `${afterStyles} flex flex-col flex-wrap gap-y-16 gap-x-24 border border-solid p-16 lg:px-56 md:p-32 md:flex-row md:items-center bg-brand-100 border-brand-200 has-[a]:hover:shadow-2`,
  'curated-secondary-deprecated': `${afterStyles} flex flex-col flex-wrap gap-y-16 gap-x-24 border border-solid p-16 md:px-24 md:flex-row md:items-center bg-neutral-0 border-neutral-200 has-[a]:hover:shadow-2`,
  'featured-compact': `w-full mx-auto gap-16 lg:gap-32 lg:flex-row lg:items-center ${afterStyles} ${defaultBackground}`,
  featured: `w-full lg:gap-24 lg:flex-row items-center ${afterStyles} ${defaultBackground}`,
  text: defaultBackground,
} as const

type Variants = keyof typeof variants

export type BaseCardProps = Tracking &
  PropsWithChildren<{
    animate?: boolean
    style?: Record<string, string>
    testId?: string
    variant?: Variants
  }>

export function CardBase(props: BaseCardProps) {
  const variantStyles = variants[props.variant || 'default']

  return (
    <article
      data-testid={props.testId}
      className={`
        duration-short-2 ease-standard active:border-primary-600 focus-within:border-primary-600 relative
        isolate flex w-full min-w-[var(--min-width)] flex-col
        transition-shadow focus-within:border hover:border-neutral-200 [&:focus-within_a]:outline-none
        ${props.animate ? 'animate-fadeIn' : ''}
        ${variantStyles}
      `}
      style={props.style}
      {...mapTrackingKeysToDataAttributes(props.tracking)}
    >
      {props.children}
    </article>
  )
}
