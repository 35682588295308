import { type ReactNode, useState } from 'react'

import { ReactComponent as CAISLogo } from './assets/cais-logo.svg'
import { DesktopNav } from './components/desktop-nav'
import { MobileNav } from './components/mobile-nav'
import { Nav } from './components/nav'
import { ProfileMenu } from './components/profile-menu'
import { buildMenuContent } from './components/shared/build-menu-content'
import { EnvironmentTag } from './components/shared/environment-tag'
import { Logo } from './components/shared/logo'
import { prepareEnvData } from './prepareEnvData'
import { NavItem, Profile } from './types'

type MainNavProps = {
  navItems: NavItem[]
  profile: Profile
  logos?: ReactNode[]
  logoHref?: string
  isExternalNav?: boolean
  isContainerNav?: boolean
  canSeeGlobalSearch?: boolean
}

export function MainNav(props: MainNavProps) {
  const {
    profile,
    navItems,
    logos = [<CAISLogo key="logo" />],
    logoHref = '/',
    isExternalNav = false,
    isContainerNav = false,
  } = props

  const [openMenu, setOpenMenu] = useState<string | null>(null)

  const env = prepareEnvData()
  const handleOpenMenu = (id: string) => setOpenMenu(id)
  const handleCloseMenu = () => setOpenMenu(null)
  const sharedProps = {
    handleCloseMenu,
    handleOpenMenu,
    menuContents: buildMenuContent({
      env,
      navItems,
    }),
    navItems,
    openMenu,
  }

  return (
    <Nav
      id={isContainerNav ? 'container-nav' : ''}
      isFixed={isExternalNav}
      handleCloseMenu={handleCloseMenu}
    >
      <MobileNav {...sharedProps} />
      <Logo env={env} logos={logos} logoHref={logoHref} />
      <EnvironmentTag />
      <DesktopNav {...sharedProps} />
      <ProfileMenu
        {...sharedProps}
        menuContents={buildMenuContent({
          env,
          navItems: [{ menu: profile?.menu, name: '' }],
        })}
        profile={profile}
      />
    </Nav>
  )
}
